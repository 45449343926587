import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import truncate from "lodash/truncate";
import { Link } from "react-router-dom";
import CarouselHeader from "./Carousel/Header";
import CarouselContent from "./Carousel/Content";
import { modelRedirectFn } from "./utils";
import Avatar from "../user/Avatar";
import { ReactComponent as ViewIcon } from "../../assets/images/home/view-icon.svg";
import PaidRibbon from "../profile/MyPassbook/PaidRibbon";
import { decodeString } from "../../utils/helper";
import { ReactComponent as PollIcon } from "../../assets/images/feed/create/poll.svg";
import FreeSlider from "../reviews/common/FreeSlide";
import VerifiedLogo from "../misc/VerifiedLogo";
import { gaEvents } from "../../utils/events";
import SmallVideoPreview from "../../containers/loggedIn/Feed/common/SmallVideoPreview";
import PlayIconWhite from "../../assets/images/common/icons/play-white.svg";
import ArrowRightIcon from "../../assets/images/explore/common/arrow-big-right.svg";

const WorkshopSlide = (props) => {
  const {
    data,
    type,
    title,
  } = props;
  const hasVideo = get(data, `[${type}].videos_meta`, "");
  const image = get(data, `[${type}].image_meta[0].original_photo`, "")
    ? get(data, `[${type}].image_meta[0].original_photo`, "")
    : hasVideo && !!hasVideo.length
      ? null
      : get(data, "placeholder_images_meta.original_photo", "");

  const text = "JOIN WORKSHOP";

  return (
    <div className="home__carousel__workshop">
      <span className="workshop__card__status">LIVE</span>
      <div className="home__carousel__survey__title">{decodeString(title)}</div>
      {image ? (
        <img
          src={image}
          className="home__carousel__survey__image"
          alt="survey slide"
        />
      ) : (
        <div>
          {hasVideo &&
            !!hasVideo.length &&
            hasVideo.map((video) => {
              const thumbnail = get(video, "media_json.thumbnail", "");
              return (
                <div style={{ position: "relative" }}>
                  <img
                    src={thumbnail}
                    className="home__carousel__survey__image"
                    alt="survey slide"
                  />
                  <img className="play" src={PlayIconWhite} alt="play-icon" />
                </div>
              );
            })}
        </div>
      )}
      <div
        className="home__carousel__survey__image__overlay"
        style={!image && hasVideo ? { pointerEvents: "none" } : {}}
      />
      <div className="home__carousel__workshop__button">{text}  <img src={ArrowRightIcon} className="arrow" alt="join-workshop" /> </div>
    </div>
  );
};

const SurveySlide = (props) => {
  const {
    data,
    type,
    title,
    profileHandle,
    name,
    profileImage,
    isVerified,
    is_tasting_expert,
    showPaid,
    viewCount,
  } = props;
  const hasVideo = get(data, `[${type}].videos_meta`, "");
  const image = get(data, `[${type}].image_meta[0].original_photo`, "")
    ? get(data, `[${type}].image_meta[0].original_photo`, "")
    : hasVideo && !!hasVideo.length
      ? null
      : get(data, "placeholder_images_meta.original_photo", "");

  const text =
    get(data, "surveys.is_private", 0) === 1 ? "SEE MORE" : "FILL SURVEY";

  return (
    <div className="home__carousel__survey">
      <div className="home__carousel__survey__title">{decodeString(title)}</div>
      {image ? (
        <img
          src={image}
          className="home__carousel__survey__image"
          alt="survey slide"
        />
      ) : (
        <div>
          {hasVideo &&
            !!hasVideo.length &&
            hasVideo.map((video) => {
              const thumbnail = get(video, "media_json.thumbnail", "");
              return (
                <div style={{ position: "relative" }}>
                  <img
                    src={thumbnail}
                    className="home__carousel__survey__image"
                    alt="survey slide"
                  />
                  <img className="play" src={PlayIconWhite} alt="play-icon" />
                </div>
              );
            })}
        </div>
      )}
      <div
        className="home__carousel__survey__image__overlay"
        style={!image && hasVideo ? { pointerEvents: "none" } : {}}
      />
      <div className="home__carousel__survey__info">
        <Link to={profileHandle}>
          <Avatar
            className="pr-manage__applicants__icon"
            src={get(profileImage, "original_photo", "")}
            alt={"user image"}
            is_tasting_expert={is_tasting_expert}
          />
        </Link>

        <div className="home__carousel__survey__info__name">
          <Link
            className="home__carousel__survey__info__name__link"
            to={profileHandle}
          >
            <span className="home__carousel__survey__info__name__link__text">
              {decodeString(name)}
            </span>
            {!!isVerified && (
              <VerifiedLogo className="home__carousel__survey__info__name__link__verifiedicon" />
            )}
          </Link>
          {viewCount && (
            <div className="home__carousel__survey__info__name__viewicon">
              <ViewIcon className="home__carousel__survey__info__name__viewicon__img" />
              <span className="home__carousel__survey__info__name__viewicon__text">
                {viewCount}
              </span>
            </div>
          )}
        </div>
        {showPaid && (
          <div className="home__carousel__survey__info__name__paidribbon">
            <PaidRibbon />
          </div>
        )}
      </div>
      <div className="home__carousel__survey__button">{text}</div>
    </div>
  );
};

const CollaborateSlide = (props) => {
  const {
    data,
    type,
    title,
    profileHandle,
    name,
    profileImage,
    isVerified,
    is_tasting_expert,
    showPaid,
    viewCount,
  } = props;
  const image = get(data, `[${type}].images_meta[0].original_photo`, "")
    ? get(data, `[${type}].images_meta[0].original_photo`, "")
    : get(data, "placeholder_images_meta.original_photo", "");

  return (
    <div className="home__carousel__collaborate">
      <div className="home__carousel__survey__title">{decodeString(title)}</div>
      {get(data, `[${type}].images_meta[0].original_photo`, "") ? (
        <img
          src={image}
          className="home__carousel__survey__image"
          alt="collaborate slide"
        />
      ) : data[type] && data[type].videos_meta &&
        data[type].videos_meta.length &&
        data[type].videos_meta[0] &&
        data[type].videos_meta[0].media_json ? (
        <SmallVideoPreview
          image={data[type].videos_meta[0].media_json.thumbnail}
          className="home__carousel__survey__image"
          playIconClassName="review-page__collaboration__image-container__playIcon-carousel"
          type="carousel_collab"
        />
      ) : (
        <img
          src={image}
          className="home__carousel__survey__image"
          alt="collaborate slide"
        />
      )}
      <div className="home__carousel__survey__image__overlay" />
      <div className="home__carousel__survey__info">
        <Link to={profileHandle}>
          <Avatar
            className="pr-manage__applicants__icon"
            src={get(profileImage, "original_photo", "")}
            alt={"user image"}
            is_tasting_expert={is_tasting_expert}
          />
        </Link>
        <div className="home__carousel__survey__info__name">
          <Link
            className="home__carousel__survey__info__name__link"
            to={profileHandle}
          >
            <span className="home__carousel__survey__info__name__link__text">
              {decodeString(name)}
            </span>
            {!!isVerified && (
              <VerifiedLogo className="home__carousel__survey__info__name__link__verifiedicon" />
            )}
          </Link>
          {viewCount && (
            <div className="home__carousel__survey__info__name__viewicon">
              <ViewIcon className="home__carousel__survey__info__name__viewicon__img" />
              <span className="home__carousel__survey__info__name__viewicon__text">
                {viewCount}
              </span>
            </div>
          )}
        </div>
        {showPaid && (
          <div className="home__carousel__survey__info__name__paidribbon">
            <PaidRibbon />
          </div>
        )}
      </div>
      <div className="home__carousel__survey__button">See More</div>
    </div>
  );
};

const ProductReviewSlide = (props) => {
  const {
    data,
    profileHandle,
    name,
    profileImage,
    isVerified,
    is_tasting_expert,
    showPaid,
    viewCount,
  } = props;
  const originalTitle = get(data, `collaborate.title`, "");
  const title = truncate(originalTitle, { length: 76 });

  const image = get(data, `collaborate.images_meta[0].original_photo`, "")
    ? get(data, `collaborate.images_meta[0].original_photo`, "")
    : get(data, "placeholder_images_meta.original_photo", "");

  return (
    <div className="home__carousel__productreview">
      <div className="home__carousel__survey__title">{decodeString(title)}</div>
      {get(data, `collaborate.images_meta[0].original_photo`, "") ? (
        <img
          src={image}
          className="home__carousel__survey__image"
          alt="collaborate slide"
        />
      ) : data["collaborate"] && data["collaborate"].videos_meta &&
        data["collaborate"].videos_meta.length &&
        data["collaborate"].videos_meta[0] &&
        data["collaborate"].videos_meta[0].media_json ? (
        <SmallVideoPreview
          image={data["collaborate"].videos_meta[0].media_json.thumbnail}
          className="home__carousel__survey__image"
          playIconClassName="review-page__collaboration__image-container__playIcon-carousel"
          type="carousel_collab"
        />
      ) : (
        <img
          src={image}
          className="home__carousel__survey__image"
          alt="collaborate slide"
        />
      )}
      {/* <img
        src={image}
        className="home__carousel__survey__image"
        alt="product slide"
      /> */}
      <div className="home__carousel__survey__image__overlay" />
      <div className="home__carousel__survey__info">
        <Link to={profileHandle}>
          <Avatar
            className="pr-manage__applicants__icon"
            src={get(profileImage, "original_photo", "")}
            alt={"user image"}
            is_tasting_expert={is_tasting_expert}
          />
        </Link>
        <div className="home__carousel__survey__info__name">
          <Link
            className="home__carousel__survey__info__name__link"
            to={profileHandle}
          >
            <span className="home__carousel__survey__info__name__link__text">
              {decodeString(name)}
            </span>
            {!!isVerified && (
              <VerifiedLogo className="home__carousel__survey__info__name__link__verifiedicon" />
            )}
          </Link>
          {viewCount && (
            <div className="home__carousel__survey__info__name__viewicon">
              <ViewIcon className="home__carousel__survey__info__name__viewicon__img" />
              <span className="home__carousel__survey__info__name__viewicon__text">
                {viewCount}
              </span>
            </div>
          )}
        </div>
        {showPaid && (
          <div className="home__carousel__survey__info__name__paidribbon">
            <PaidRibbon />
          </div>
        )}
      </div>
      <div className="home__carousel__survey__button">See More</div>
    </div>
  );
};

const PollingSlide = (props) => {
  const {
    data,
    type,
    title,
    profileHandle,
    name,
    profileImage,
    isVerified,
    is_tasting_expert,
    showPaid,
    viewCount,
  } = props;

  const image = get(data, `[${type}].image_meta.original_photo`, "")
    ? get(data, `[${type}].image_meta.original_photo`, "")
    : get(data, "placeholder_images_meta.original_photo", "");

  return (
    <div className="home__carousel__polling">
      {get(data, `[${type}].images_meta[0].original_photo`, "") ? (
        <img
          src={image}
          className="home__carousel__survey__image"
          alt="collaborate slide"
        />
      ) : data[type] && data[type].videos_meta &&
        data[type].videos_meta.length &&
        data[type].videos_meta[0] &&
        data[type].videos_meta[0].media_json ? (
        <SmallVideoPreview
          image={data[type].videos_meta[0].media_json.thumbnail}
          className="home__carousel__survey__image"
          playIconClassName="review-page__collaboration__image-container__playIcon-carousel"
          type="carousel_collab"
        />
      ) : (
        <img
          src={image}
          className="home__carousel__survey__image"
          alt="collaborate slide"
        />
      )}
      {/* <img src={image} className="home__carousel__survey__image" /> */}
      {showPaid && (
        <div className="home__carousel__polling__paidribbon">
          <PaidRibbon />
        </div>
      )}
      <div className="home__carousel__survey__title">{decodeString(title)}</div>

      <div className="home__carousel__polling__info">
        <Link to={profileHandle}>
          <Avatar
            className="pr-manage__applicants__icon"
            src={get(profileImage, "original_photo", "")}
            alt={"user image"}
            is_tasting_expert={is_tasting_expert}
          />
        </Link>
        <div className="home__carousel__polling__info__name">
          <Link
            className="home__carousel__polling__info__name__link"
            to={profileHandle}
          >
            <span className="home__carousel__polling__info__name__link__text">
              {decodeString(name)}
            </span>
            {!!isVerified && (
              <VerifiedLogo className="home__carousel__polling__info__name__link__verifiedicon" />
            )}
          </Link>

          {viewCount && (
            <div className="home__carousel__polling__info__name__viewicon">
              <ViewIcon className="home__carousel__polling__info__name__viewicon__img" />
              <span className="home__carousel__polling__info__name__viewicon__text">
                {viewCount}
              </span>
            </div>
          )}
        </div>
        <div className="home__carousel__polling__info__pollbtn">
          <PollIcon className="home__carousel__polling__info__pollbtn__icon" />
          <span className="home__carousel__polling__info__pollbtn__text">
            Poll
          </span>
        </div>
      </div>
    </div>
  );
};

const ProductSlide = (props) => {
  const { data, type, showPaid } = props;
  const title = get(data, `[${type}].name`, "");
  const brandName = get(data, `[${type}].brand_name`, "");

  const image = get(data, `[${type}].images_meta[0].original_photo`, "")
    ? get(data, `[${type}].images_meta[0].original_photo`, "")
    : get(data, "placeholder_images_meta.original_photo", "");

  const totalRatingCount = get(data, "meta.overall_rating.count", 0);
  const maxRating = get(data, "meta.overall_rating.max_rating", 0);
  const overallRating = get(data, "meta.overall_rating.overall_rating", 0);

  return (
    <div className="home__carousel__product">
      {get(data, `[${type}].images_meta[0].original_photo`, "") ? (
        <img
          src={image}
          className="home__carousel__survey__image"
          alt="collaborate slide"
        />
      ) : data[type] && data[type].videos_meta &&
        data[type].videos_meta.length &&
        data[type].videos_meta[0] &&
        data[type].videos_meta[0].media_json ? (
        <SmallVideoPreview
          image={data[type].videos_meta[0].media_json.thumbnail}
          className="home__carousel__survey__image"
          playIconClassName="review-page__collaboration__image-container__playIcon-carousel"
          type="carousel_collab"
        />
      ) : (
        <img
          src={image}
          className="home__carousel__survey__image"
          alt="collaborate slide"
        />
      )}
      {/* <img src={image} className="home__carousel__survey__image" /> */}
      {showPaid && (
        <div className="home__carousel__polling__paidribbon">
          <PaidRibbon />
        </div>
      )}
      <div className="home__carousel__product__title home__carousel__survey__title">
        {decodeString(title)}
      </div>
      <div className="home__carousel__product__brand">{`by ${decodeString(
        brandName
      )}`}</div>
      <div className="home__carousel__product__review">
        {totalRatingCount > 0 ? (
          <div className="home__carousel__product__review__nonempty">
            <div className="home__carousel__product__review__nonempty__rating">
              <span className="home__carousel__product__review__nonempty__rating__icon">
                ★
              </span>
              <span className="home__carousel__product__review__nonempty__rating__text">{`${overallRating} / ${maxRating}`}</span>
            </div>
            <span className="home__carousel__product__review__empty__text">{`${totalRatingCount} Review${totalRatingCount > 1 ? "s" : ""
              }`}</span>
          </div>
        ) : (
          <div className="home__carousel__product__review__empty">
            <div className="home__carousel__product__review__empty">
              <span className="home__carousel__product__review__empty__icon">
                ★
              </span>
              <span className="home__carousel__product__review__empty__text">
                No reviews yet
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const CarouselComponent = (props) => {
  const { data, isMobile } = props;

  const modelName = get(data, "model_name", "");

  const handleClick = (obj) => {
    const cardModelName = get(obj, "type");
    const modelId =
      get(obj, "type") === "product-review"
        ? get(obj, `collaborate.id`, "")
        : get(obj, `[${get(obj, "type")}].id`, "");

    return modelRedirectFn(cardModelName, modelId);
  };

  const getImageCard = (obj) => {
    const type = get(obj, "type", "");
    const originalTitle = get(obj, `[${type}].title`, "");
    const title = truncate(decodeString(originalTitle), { length: 76 });
    const profileHandle = get(obj, "company.id", "")
      ? `/companies/${get(obj, "company.id", "")}`
      : get(obj, "profile.handle", "")
        ? `@${get(obj, "profile.handle", "")}`
        : "";

    const name = get(obj, "company.name", "")
      ? get(obj, "company.name", "")
      : get(obj, "profile.name", "");

    const isVerified = get(obj, "profile.verified", false);
    const profileImage = get(obj, "company.logo_meta", "")
      ? JSON.parse(get(obj, "company.logo_meta", ""))
      : get(obj, "profile.image_meta", "")
        ? JSON.parse(get(obj, "profile.image_meta", ""))
        : "";

    const is_tasting_expert = get(obj, "profile.is_tasting_expert", 0);

    const showPaid = get(obj, "meta.isPaid", false);
    const viewCount = get(obj, "meta.seen_count", "");
    const cardProps = {
      type,
      title,
      profileHandle,
      name,
      profileImage,
      isVerified,
      is_tasting_expert,
      showPaid,
      viewCount,
    };
    switch (modelName) {
      case "surveys":
        return <SurveySlide data={obj} {...cardProps} />;
      case "collaborate":
        return <CollaborateSlide data={obj} {...cardProps} />;
      case "product-review":
        return <ProductReviewSlide data={obj} {...cardProps} />;
      case "polling":
        return <PollingSlide data={obj} {...cardProps} />;
      case "product":
        return <ProductSlide data={obj} {...cardProps} />;
      case "workshop":
        return <WorkshopSlide data={obj} {...cardProps} />;
      default:
        return null;
    }
  };

  const listItems = get(data, "elements", []).map((obj, i) => {
    return (
      <Link
        key={i}
        className={`cursor-pointer home__carousel__slideritem ${modelName}`}
        to={() => handleClick(obj)}
        onClick={() => {
          gaEvents.landingpage.carousel("Click", modelName);
        }}
      >
        {getImageCard(obj)}
      </Link>
    );
  });

  const seeMoreGALabel =
    modelName === "polling" && get(data, "value", "") === "poll_result"
      ? "Poll Result"
      : modelName;
  const swipeGALabel =
    modelName === "polling" && get(data, "value", "") === "poll_result"
      ? "Poll Result"
      : modelName;
  return (
    <div className="home__card">
      <CarouselHeader
        data={data}
        gaFn={() =>
          gaEvents.landingpage.carousel("See More Click", seeMoreGALabel)
        }
      />
      {isMobile ? (
        <FreeSlider>{listItems}</FreeSlider>
      ) : (
        <CarouselContent
          gaFn={() => gaEvents.landingpage.carousel("Swipe", swipeGALabel)}
        >
          {listItems}
        </CarouselContent>
      )}
    </div>
  );
};

SurveySlide.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  title: PropTypes.string,
  profileHandle: PropTypes.string,
  name: PropTypes.string,
  profileImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isVerified: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  is_tasting_expert: PropTypes.number,
  showPaid: PropTypes.bool,
  viewCount: PropTypes.string,
};
CollaborateSlide.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  title: PropTypes.string,
  profileHandle: PropTypes.string,
  name: PropTypes.string,
  profileImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isVerified: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  is_tasting_expert: PropTypes.number,
  showPaid: PropTypes.bool,
  viewCount: PropTypes.string,
};
ProductReviewSlide.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  title: PropTypes.string,
  profileHandle: PropTypes.string,
  name: PropTypes.string,
  profileImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isVerified: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  is_tasting_expert: PropTypes.number,
  showPaid: PropTypes.bool,
  viewCount: PropTypes.string,
};
PollingSlide.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  title: PropTypes.string,
  profileHandle: PropTypes.string,
  name: PropTypes.string,
  profileImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isVerified: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  is_tasting_expert: PropTypes.number,
  showPaid: PropTypes.bool,
  viewCount: PropTypes.string,
};
ProductSlide.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  showPaid: PropTypes.bool,
};

CarouselComponent.propTypes = {
  data: PropTypes.object,
  isMobile: PropTypes.bool,
};
export default CarouselComponent;
