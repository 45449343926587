import { combineReducers } from "redux";
import careers from "./careers";
import team from "./team";
import login from "./login";
import collaborate from "./collaborate/collaborate";
import manage from "./collaborate/manage";
import user from "./user";
import feedReducer from "./feed/index";
import chatReducer from "./chat/index";
import tagging from "./tagging";
import modalReducer from "./modals/index";
import profileReducer from "./profile/index";
import collaborationReducer from "./collaborations/index";
import settings from "./settings";
import accountManagement from "./accountManagement";
import detailPageReducer from "./details/index";
import notifications from "./notifications";
import onboardingReducer from "./onboarding/index";
import companyReducer from "./company/index";
import productReducer from "./products/index";
import tasting from "./tasting/index";
import publicView from "./publicView/view";
// import sharedPublicView from './publicView/shared';
import miscReducer from "./misc/index";
import businessReducer from "./business/index";
import reviewsReducer from "./reviews/index";
import explore from "./explore/index";
import hashtagReducer from "./hashtag/index";
import surveyReducer from "./survey/index";
import quizReducer from "./quiz/index";
import seeAllPageReducer from "./seeAllPages/index";
import landingPageReducer from "./landingPage/index";
import { LOGOUT_REQUEST } from "../actions/login/constants";
import commonReducer from "./commonReducers";
import workshopReducer from "./workshop/index"

// TODO: Refactor it depending on the login state
const appReducer = combineReducers({
  user,
  careers,
  team,
  login,
  collaborate,
  manage,
  feedReducer,
  tagging,
  modalReducer,
  profileReducer,
  collaborationReducer,
  detailPageReducer,
  settings,
  accountManagement,
  notifications,
  chatReducer,
  onboardingReducer,
  companyReducer,
  tasting,
  publicView,
  // sharedPublicView,
  productReducer,
  miscReducer,
  businessReducer,
  reviewsReducer,
  explore,
  hashtagReducer,
  surveyReducer,
  quizReducer,
  seeAllPageReducer,
  landingPageReducer,
  commonReducer,
  workshopReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_REQUEST) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
