import { decodeString } from "./helper";

export const postMaxLength = 2000;
export const readMoreLength = 200;
export const emailLength = 254;
export const phoneLength = 20;
export const demographyInputLength = 4;
export const nameLength = 70;
export const otpLength = 6;
export const passwordLength = 80;
export const minPasswordLength = 6;
export const aboutLength = 300;
export const phoneMaxLength = 15;
export const otpTimerInSeconds =
  process.env.REACT_APP_NODE_ENV === "production" ? 59 : 15;

export const collabLengths = {
  title: {
    max: 150,
    min: 10,
  },
  description: {
    max: 2000,
  },
  financials: {
    max: 150,
  },
  pr: {
    description: {
      max: 2000,
      min: 300,
    },
    batches: {
      title: 140,
      note: 140,
    },
    protocol: {
      description: 5000
    }
  },
};

export const surveyLengths = {
  title: 150,
  description: 2000,
  question: {
    title: 2000,
    subtitle: 2000,
    choice: 100,
  },
  section: {
    title: 150,
    description: 2000,
  },
  playable: {
    shortAnswer: 150,
    longAnswer: 2000,
    anyOther: 2000,
    textAreaMaxRows: 10,
  },
};
export const quizLengths = {
  title: 150,
  description: 2000,
  question: {
    title: 2000,
    choice: 60,
  },
},
  maxQuizFormImageWidth = 343,
  maxQuizFormImageHeight = 192.43,
  maxQuizOptionImageWidth = 245,
  maxQuizOptionImageHeight = 196,
  maxQuizOptionPlayableImageWidth = 350,
  maxQuizOptionPlayableImageHeight = 280,
  maxQuizQuestionPlayableImageWidth = 760,
  maxQuizQuestionPlayableImageHeight = 445.5,
  quizQuestionImageRatio = 1.77, // 16/9
  quizOptionImageRatio = 1.25; // 5/4

// types of posts
export const availableFeedCardPostTypes = {
  shoutout: "shoutout",
  photo: "photo",
  collaboration: "collaborate",
  poll: "polling",
  product: "product",
  suggestion: "suggestion",
  feedCard: "feedCard",
  survey: "surveys",
  quiz: "quiz",
  workshop: "workshop",
};

// tasting Limits
export const tastingLengths = {
  comments: {
    max: 2000,
  },
  anyOther: {
    max: 140,
  },
};

// mime type for documents
export const documentMimeTypes = `application/msword,
        application/vnd.openxmlformats-officedocument.wordprocessingml.document,
        application/octet-stream,
        application/pdf`,
  profileImageMimeType = `image/png, image/jpg, image/jpeg, image/x-png`;

// max file size
export const maxFileSize = 10485760; // 10MB
export const maxVideoFileSize = 104857600; //100MB  //20971520; //20MB

// chat variables
export const chatLimits = {
  inputMaxLength: 2000,
  chatMaxLength: 2000,
  taglineMaxLength: 140,
  inlineInputMaxlength: 255,
  chatGroupName: 25,
  chatGroupMaxParticipants: 250,
};

// profile limits
export const profileLimits = {
  tagline: 140,
  about: 2000,
  name: 140,
  handle: 145,
  education: {
    text: 150,
    description: 2000,
  },
  work: {
    text: 150,
    description: 2000,
  },
  training: {
    text: 150,
    description: 70,
  },
  website: 300,
};

// company limits
export const companyLimits = {
  name: 140,
  about: 2000,
  tagline: 140,
  email: 300,
  awards: {
    text: 150,
    description: 2000,
  },
  patent: {
    texts: 150,
    description: 2000,
    number: 20,
  },
  publisher: {
    texts: 150,
    description: 2000,
  },
  website: 300,
};

//available genders
export const availableGenders = ["Male", "Female", "Other"];

// available container lengths
export const screenBreakpoints = {
  mob: 820,
  tab: 1024,
};

// blog links
export const blogLinks = {
  all: "https://blog.tagtaste.com/",
};

// available months
export const months = [
  { numeric: "01", fullName: "January", shortName: "Jan" },
  { numeric: "02", fullName: "Feburary", shortName: "Feb" },
  { numeric: "03", fullName: "March", shortName: "Mar" },
  { numeric: "04", fullName: "April", shortName: "Apr" },
  { numeric: "05", fullName: "May", shortName: "May" },
  { numeric: "06", fullName: "June", shortName: "Jun" },
  { numeric: "07", fullName: "July", shortName: "Jul" },
  { numeric: "08", fullName: "August", shortName: "Aug" },
  { numeric: "09", fullName: "September", shortName: "Sep" },
  { numeric: "10", fullName: "October", shortName: "Oct" },
  { numeric: "11", fullName: "November", shortName: "Nov" },
  { numeric: "12", fullName: "December", shortName: "Dec" },
];

// types of feed post
export const feedPostTypes = {
  advertisement: "advertisement",
  suggestion: "suggestion",
};

// default react select types
export const defaultReactSelectTypes = (ChevronDown) => ({
  backgroundImage: `url(${ChevronDown})`,
  backgroundSize: 20,
  backgroundPosition: 0,
  width: 32,
  height: "100%",
  display: "flex",
  backgroundRepeat: "no-repeat",
  "& svg": { display: "none" },
});

export const ttfbLimits = {
  leads: {
    name: 70,
    description: 500,
    company: 200,
  },
  products: {
    title: 70,
    description: 500,
  },
  consistency: {
    product: {
      title: 150,
      description: 2000,
    },
    batch: {
      batchCode: 140,
    },
  },
};

// profile completion request
export const handledMandatoryItemsForCampusConnect = "phone verified_phone",
  handledMandatoryItemsForGetSample = "phone verified_phone";

export const pollTypes = {
  text: 3,
  imageQuestion: 1,
  imageOption: 2,
},
  maxPollQuestionImageWidth = 538,
  maxPollQuestionImageHeight = 200,
  pollQuestionImageRatio = 1.77, // 16/9
  pollOptionImageRatio = 1.25, // 5/4
  pollTextLimits = {
    textOption: 40,
    imageOptionText: 35,
    title: 2000,
  };

export const genericErrorText = {
  general: "Something went wrong! Please try again.",
};

// meta tags
export const seoTitles = {
  survey: {
    create: `TagTaste | Survey | Create`,
    edit: `TagTaste | Survey | Edit`,
    all: "TagTaste | Surveys",
    mySurvey: "TagTaste | My Survey",
    manage: "TagTaste | Survey | Manage",
    reports: "TagTaste | Survey | Reports",
  },
  invitation: {
    myInvitation: "TagTaste | My Invitations",
  },
  quiz: {
    create: `TagTaste | Quiz | Create`,
    edit: `TagTaste | Quiz | Edit`,
    myQuiz: "TagTaste | My Quizzes",
    all: "TagTaste | Quizzes",
    reports: "TagTaste | Quiz | Reports",
  },
  workshop: {
    myWorkshop: "TagTaste | My Workshop",
    all: "TagTaste | Workshops",
  },
  collaborations: {
    all: "TagTaste | Collaborations",
    edit: "TagTaste | Collaboration | Edit",
    create: "TagTaste | Collaboration | Create",
    myCollaborations: "TagTaste | My Collaboration",
    manage: "TagTaste | Collaboration | Manage",
  },
  productReview: {
    edit: "TagTaste | Collaboration | Private Review | Edit",
    create: "TagTaste | Collaboration | Private Review | Create",
    manage: "TagTaste | Collaboration | Private Review | Manage",
    reports: "TagTaste | Collaboration | Private Review | Reports",
    tastingProcess: "TagTaste | Collaboration | Private Review | Tasting",
    myTastings: "TagTaste | Collaboration | Private Review | My Tasting",
  },
  reviews: {
    all: "TagTaste | Reviews",
  },
  polls: {
    all: "TagTaste | Polls",
  },
  products: {
    all: "TagTaste | Products",
    tastingProcess: "TagTaste | Product | Tasting",
    review: "TagTaste | Review",
  },
  profile: {
    all: "TagTaste | People",
    confirmPassword: "TagTaste | Profile | Confirm Password",
    passbook: "TagTaste | Profile | My Passbook",
    paidTasterProgram: "TagTaste | Paid Taster Program",
    followers: "TagTaste | Profile | Followers",
    following: "TagTaste | Profile | Following ",
    photos: "TagTaste | Profile | Photos",
    badges: "TagTaste | Profile | Badges",
    single: (name) => `TagTaste | ${decodeString(name)} | Profile`,
  },
  companies: {
    all: "TagTaste | Companies",
    photos: "TagTaste | Company | Photos",
    followers: "TagTaste | Company | Followers",
  },
  ttfb: {
    partners: "TagTaste | Business | Partners",
    manufacturers: "TagTaste | Business | Manufacturers",
    distributors: "TagTaste | Business | Distributors",
    products: "TagTaste | Business | Products",
    default: "TagTaste | Business",
    product: "TagTaste | Business | Product",
    trackConsistency: "TagTaste | Business | Consistency",
    allProducts: "TagTaste | Business | Consistency | Products",
    closedProducts: "TagTaste | Business | Consistency | Products | Closed",
    users: "TagTaste | Business | Consistency | Users",
    reports: "TagTaste | Business | Consistency | Reports",
  },
  collection: {
    single: "TagTaste | Collection",
  },
  loggedIn: {
    onboarding: "TagTaste | Onboarding",
    explore: "TagTaste | Explore",
    notifications: "TagTaste | Notifications",
    settings: "TagTaste | Settings",
    feed: "TagTaste | Feed",
    home: "TagTaste | Home",
    messenger: "TagTaste | Messenger",
    accountManagement: {
      deactivate: "TagTaste | Deactivate Profile",
      delete: "TagTaste | Delete Profile",
      default: "TagTaste | Account Management",
    },
    delete: "TagTaste | Delete Profile",
  },
  loggedOut: {
    passwordReset: "TagTaste | Password | Reset",
    passwordCreate: "TagTaste | Password | Create",
    career: "TagTaste | Career",
    community: "TagTaste | Community Guidelines",
    verifyEmail: "TagTaste | Email | Verify",
    login: "TagTaste | Login",
    register: "TagTaste | Register",
    forgot: "TagTaste | Forgot Password",
    privacy: "TagTaste | Privacy Policy",
    terms: "TagTaste | Terms & Conditions",
    faqs: "TagTaste | FAQs - Help Centre",
    notFound: "TagTaste | Page Not Found",
    home: "TagTaste - Community for food professionals",
    accountManagement: {
      deactivate: "TagTaste | Deactivate Profile",
      delete: "TagTaste | Delete Profile",
      default: "TagTaste | Account Management",
    },
  },
  hashtag: {
    trending: "TagTaste | Hashtag | Trending",
    feed: "TagTaste | Hashtag | Feed",
  },
};

export const MAX_HASHTAG_LENGTH = 50;

// countries
export const countries = [
  { country: "Afghanistan", calling_code: "93", emoji: "🇦🇫" },
  { country: "Albania", calling_code: "355", emoji: "🇦🇱" },
  { country: "Algeria", calling_code: "213", emoji: "🇩🇿" },
  { country: "American Samoa", calling_code: "1684", emoji: "🇦🇸" },
  { country: "Andorra", calling_code: "376", emoji: "🇦🇩" },
  { country: "Angola", calling_code: "244", emoji: "🇦🇴" },
  { country: "Anguilla", calling_code: "1264", emoji: "🇦🇮" },
  { country: "Antarctica", calling_code: "672", emoji: "🇦🇶" },
  { country: "Antigua and Barbuda", calling_code: "1268", emoji: "🇦🇬" },
  { country: "Argentina", calling_code: "54", emoji: "🇦🇷" },
  { country: "Armenia", calling_code: "374", emoji: "🇦🇲" },
  { country: "Aruba", calling_code: "297", emoji: "🇦🇼" },
  { country: "Australia", calling_code: "61", emoji: "🇦🇺" },
  { country: "Austria", calling_code: "43", emoji: "🇦🇹" },
  { country: "Azerbaijan", calling_code: "994", emoji: "🇦🇿" },
  { country: "Bahamas", calling_code: "1242", emoji: "🇧🇸" },
  { country: "Bahrain", calling_code: "973", emoji: "🇧🇭" },
  { country: "Bangladesh", calling_code: "880", emoji: "🇧🇩" },
  { country: "Barbados", calling_code: "1246", emoji: "🇧🇧" },
  { country: "Belarus", calling_code: "375", emoji: "🇧🇾" },
  { country: "Belgium", calling_code: "32", emoji: "🇧🇪" },
  { country: "Belize", calling_code: "501", emoji: "🇧🇿" },
  { country: "Benin", calling_code: "229", emoji: "🇧🇯" },
  { country: "Bermuda", calling_code: "1441", emoji: "🇧🇲" },
  { country: "Bhutan", calling_code: "975", emoji: "🇧🇹" },
  { country: "Bolivia", calling_code: "591", emoji: "🇧🇴" },
  { country: "Bosnia and Herzegovina", calling_code: "387", emoji: "🇧🇦" },
  { country: "Botswana", calling_code: "267", emoji: "🇧🇼" },
  { country: "Brazil", calling_code: "55", emoji: "🇧🇷" },
  {
    country: "British Indian Ocean Territory",
    calling_code: "246",
    emoji: "🇮🇴",
  },
  { country: "Brunei", calling_code: "673", emoji: "🇧🇳" },
  { country: "Bulgaria", calling_code: "359", emoji: "🇧🇬" },
  { country: "Burkina Faso", calling_code: "226", emoji: "🇧🇫" },
  { country: "Burundi", calling_code: "257", emoji: "🇧🇮" },
  { country: "Cambodia", calling_code: "855", emoji: "🇰🇭" },
  { country: "Cameroon", calling_code: "237", emoji: "🇨🇲" },
  { country: "Canada", calling_code: "1", emoji: "🇨🇦" },
  { country: "Cape Verde", calling_code: "238", emoji: "🇨🇻" },
  { country: "Cayman Islands", calling_code: "1345", emoji: "🇰🇾" },
  { country: "Central African Republic", calling_code: "236", emoji: "🇨🇫" },
  { country: "Chad", calling_code: "235", emoji: "🇹🇩" },
  { country: "Chile", calling_code: "56", emoji: "🇨🇱" },
  { country: "China", calling_code: "86", emoji: "🇨🇳" },
  { country: "Christmas Island", calling_code: "61", emoji: "🇨🇽" },
  { country: "Cocos (Keeling) Islands", calling_code: "61", emoji: "🇨🇨" },
  { country: "Colombia", calling_code: "57", emoji: "🇨🇴" },
  { country: "Comoros", calling_code: "269", emoji: "🇰🇲" },
  { country: "Congo", calling_code: "242", emoji: "🇨🇬" },
  { country: "Cook Islands", calling_code: "682", emoji: "🇨🇰" },
  { country: "Costa Rica", calling_code: "506", emoji: "🇨🇷" },
  { country: "Croatia", calling_code: "385", emoji: "🇭🇷" },
  { country: "Cuba", calling_code: "53", emoji: "🇨🇺" },
  { country: "Cyprus", calling_code: "357", emoji: "🇨🇾" },
  { country: "Czech Republic", calling_code: "420", emoji: "🇨🇿" },
  { country: "Denmark", calling_code: "45", emoji: "🇩🇰" },
  { country: "Djibouti", calling_code: "253", emoji: "🇩🇯" },
  { country: "Dominica", calling_code: "1767", emoji: "🇩🇲" },
  { country: "Dominican Republic", calling_code: "1849", emoji: "🇩🇴" },
  { country: "Ecuador", calling_code: "593", emoji: "🇪🇨" },
  { country: "Egypt", calling_code: "20", emoji: "🇪🇬" },
  { country: "El Salvador", calling_code: "503", emoji: "🇸🇻" },
  { country: "England", calling_code: "44", emoji: "🏴󠁧󠁢󠁥󠁮󠁧󠁿󠁧󠁢󠁥󠁮󠁧󠁿" },
  { country: "Equatorial Guinea", calling_code: "240", emoji: "🇬🇶" },
  { country: "Eritrea", calling_code: "291", emoji: "🇪🇷" },
  { country: "Estonia", calling_code: "372", emoji: "🇪🇪" },
  { country: "Ethiopia", calling_code: "251", emoji: "🇪🇹" },
  { country: "Falkland Islands", calling_code: "500", emoji: "🇫🇰" },
  { country: "Faroe Islands", calling_code: "298", emoji: "🇫🇴" },
  { country: "Fiji Islands", calling_code: "679", emoji: "🇫🇯" },
  { country: "Finland", calling_code: "358", emoji: "🇫🇮" },
  { country: "France", calling_code: "33", emoji: "🇫🇷" },
  { country: "French Guiana", calling_code: "594", emoji: "🇬🇫" },
  { country: "French Polynesia", calling_code: "689", emoji: "🇵🇫" },
  { country: "Gabon", calling_code: "241", emoji: "🇬🇦" },
  { country: "Gambia", calling_code: "220", emoji: "🇬🇲" },
  { country: "Georgia", calling_code: "995", emoji: "🇬🇪" },
  { country: "Germany", calling_code: "49", emoji: "🇩🇪" },
  { country: "Ghana", calling_code: "233", emoji: "🇬🇭" },
  { country: "Gibraltar", calling_code: "350", emoji: "🇬🇮" },
  { country: "Greece", calling_code: "30", emoji: "🇬🇷" },
  { country: "Greenland", calling_code: "299", emoji: "🇬🇱" },
  { country: "Grenada", calling_code: "1473", emoji: "🇬🇩" },
  { country: "Guadeloupe", calling_code: "590", emoji: "🇬🇵" },
  { country: "Guam", calling_code: "1671", emoji: "🇬🇺" },
  { country: "Guatemala", calling_code: "502", emoji: "🇬🇹" },
  { country: "Guinea", calling_code: "224", emoji: "🇬🇳" },
  { country: "Guinea-Bissau", calling_code: "245", emoji: "🇬🇼" },
  { country: "Guyana", calling_code: "592", emoji: "🇬🇾" },
  { country: "Haiti", calling_code: "509", emoji: "🇭🇹" },
  { country: "Honduras", calling_code: "504", emoji: "🇭🇳" },
  { country: "Hong Kong", calling_code: "852", emoji: "🇭🇰" },
  { country: "Hungary", calling_code: "36", emoji: "🇭🇺" },
  { country: "Iceland", calling_code: "354", emoji: "🇮🇸" },
  { country: "India", calling_code: "91", emoji: "🇮🇳" },
  { country: "Indonesia", calling_code: "62", emoji: "🇮🇩" },
  { country: "Iran", calling_code: "98", emoji: "🇮🇷" },
  { country: "Iraq", calling_code: "964", emoji: "🇮🇶" },
  { country: "Ireland", calling_code: "353", emoji: "🇮🇪" },
  { country: "Israel", calling_code: "972", emoji: "🇮🇱" },
  { country: "Italy", calling_code: "39", emoji: "🇮🇹" },
  { country: "Ivory Coast", calling_code: "225", emoji: "🇨🇮" },
  { country: "Jamaica", calling_code: "1876", emoji: "🇯🇲" },
  { country: "Japan", calling_code: "81", emoji: "🇯🇵" },
  { country: "Jordan", calling_code: "962", emoji: "🇯🇴" },
  { country: "Kazakhstan", calling_code: "7", emoji: "🇰🇿" },
  { country: "Kenya", calling_code: "254", emoji: "🇰🇪" },
  { country: "Kiribati", calling_code: "686", emoji: "🇰🇮" },
  { country: "Kuwait", calling_code: "965", emoji: "🇰🇼" },
  { country: "Kyrgyzstan", calling_code: "996", emoji: "🇰🇬" },
  { country: "Laos", calling_code: "856", emoji: "🇱🇦" },
  { country: "Latvia", calling_code: "371", emoji: "🇱🇻" },
  { country: "Lebanon", calling_code: "961", emoji: "🇱🇧" },
  { country: "Lesotho", calling_code: "266", emoji: "🇱🇸" },
  { country: "Liberia", calling_code: "231", emoji: "🇱🇷" },
  { country: "Liechtenstein", calling_code: "423", emoji: "🇱🇮" },
  { country: "Lithuania", calling_code: "370", emoji: "🇱🇹" },
  { country: "Luxembourg", calling_code: "352", emoji: "🇱🇺" },
  { country: "North Macedonia", calling_code: "389", emoji: "🇲🇰" },
  { country: "Madagascar", calling_code: "261", emoji: "🇲🇬" },
  { country: "Malawi", calling_code: "265", emoji: "🇲🇼" },
  { country: "Malaysia", calling_code: "60", emoji: "🇲🇾" },
  { country: "Maldives", calling_code: "960", emoji: "🇲🇻" },
  { country: "Mali", calling_code: "223", emoji: "🇲🇱" },
  { country: "Malta", calling_code: "356", emoji: "🇲🇹" },
  { country: "Marshall Islands", calling_code: "692", emoji: "🇲🇭" },
  { country: "Martinique", calling_code: "596", emoji: "🇲🇶" },
  { country: "Mauritania", calling_code: "222", emoji: "🇲🇷" },
  { country: "Mauritius", calling_code: "230", emoji: "🇲🇺" },
  { country: "Mayotte", calling_code: "262", emoji: "🇾🇹" },
  { country: "Mexico", calling_code: "52", emoji: "🇲🇽" },
  {
    country: "Micronesia, Federated States of",
    calling_code: "691",
    emoji: "🇫🇲",
  },
  { country: "Moldova", calling_code: "373", emoji: "🇲🇩" },
  { country: "Monaco", calling_code: "377", emoji: "🇲🇨" },
  { country: "Mongolia", calling_code: "976", emoji: "🇲🇳" },
  { country: "Montserrat", calling_code: "1664", emoji: "🇲🇸" },
  { country: "Morocco", calling_code: "212", emoji: "🇲🇦" },
  { country: "Mozambique", calling_code: "258", emoji: "🇲🇿" },
  { country: "Myanmar", calling_code: "95", emoji: "🇲🇲" },
  { country: "Namibia", calling_code: "264", emoji: "🇳🇦" },
  { country: "Nauru", calling_code: "674", emoji: "🇳🇷" },
  { country: "Nepal", calling_code: "977", emoji: "🇳🇵" },
  { country: "Netherlands", calling_code: "31", emoji: "🇳🇱" },
  { country: "New Caledonia", calling_code: "687", emoji: "🇳🇨" },
  { country: "New Zealand", calling_code: "64", emoji: "🇳🇿" },
  { country: "Nicaragua", calling_code: "505", emoji: "🇳🇮" },
  { country: "Niger", calling_code: "227", emoji: "🇳🇪" },
  { country: "Nigeria", calling_code: "234", emoji: "🇳🇬" },
  { country: "Niue", calling_code: "683", emoji: "🇳🇺" },
  { country: "Norfolk Island", calling_code: "672", emoji: "🇳🇫" },
  { country: "North Korea", calling_code: "850", emoji: "🇰🇵" },
  { country: "Northern Mariana Islands", calling_code: "1670", emoji: "🇲🇵" },
  { country: "Norway", calling_code: "47", emoji: "🇳🇴" },
  { country: "Oman", calling_code: "968", emoji: "🇴🇲" },
  { country: "Pakistan", calling_code: "92", emoji: "🇵🇰" },
  { country: "Palau", calling_code: "680", emoji: "🇵🇼" },
  { country: "Panama", calling_code: "507", emoji: "🇵🇦" },
  { country: "Papua New Guinea", calling_code: "675", emoji: "🇵🇬" },
  { country: "Paraguay", calling_code: "595", emoji: "🇵🇾" },
  { country: "Peru", calling_code: "51", emoji: "🇵🇪" },
  { country: "Philippines", calling_code: "63", emoji: "🇵🇭" },
  { country: "Poland", calling_code: "48", emoji: "🇵🇱" },
  { country: "Portugal", calling_code: "351", emoji: "🇵🇹" },
  { country: "Puerto Rico", calling_code: "1939", emoji: "🇵🇷" },
  { country: "Qatar", calling_code: "974", emoji: "🇶🇦" },
  { country: "Romania", calling_code: "40", emoji: "🇷🇴" },
  { country: "Russian Federation", calling_code: "7", emoji: "🇷🇺" },
  { country: "Rwanda", calling_code: "250", emoji: "🇷🇼" },
  { country: "Saint Helena", calling_code: "290", emoji: "🇸🇭" },
  { country: "Saint Kitts and Nevis", calling_code: "1869", emoji: "🇰🇳" },
  { country: "Saint Lucia", calling_code: "1758", emoji: "🇱🇨" },
  {
    country: "Saint Vincent and the Grenadines",
    calling_code: "1784",
    emoji: "🇻🇨",
  },
  { country: "Samoa", calling_code: "685", emoji: "🇼🇸" },
  { country: "San Marino", calling_code: "378", emoji: "🇸🇲" },
  { country: "Saudi Arabia", calling_code: "966", emoji: "🇸🇦" },
  { country: "Scotland", calling_code: "44", emoji: "🏴󠁧󠁢󠁳󠁣󠁴󠁿" },
  { country: "Senegal", calling_code: "221", emoji: "🇸🇳" },
  { country: "Seychelles", calling_code: "248", emoji: "🇸🇨" },
  { country: "Sierra Leone", calling_code: "232", emoji: "🇸🇱" },
  { country: "Singapore", calling_code: "65", emoji: "🇸🇬" },
  { country: "Slovakia", calling_code: "421", emoji: "🇸🇰" },
  { country: "Slovenia", calling_code: "386", emoji: "🇸🇮" },
  { country: "Solomon Islands", calling_code: "677", emoji: "🇸🇧" },
  { country: "Somalia", calling_code: "252", emoji: "🇸🇴" },
  { country: "South Africa", calling_code: "27", emoji: "🇿🇦" },
  {
    country: "South Georgia and the South Sandwich Islands",
    calling_code: "500",
    emoji: "🇬🇸",
  },
  { country: "South Korea", calling_code: "82", emoji: "🇰🇷" },
  { country: "South Sudan", calling_code: "211", emoji: "🇸🇸" },
  { country: "Spain", calling_code: "34", emoji: "🇪🇸" },
  { country: "Sri Lanka", calling_code: "94", emoji: "🇱🇰" },
  { country: "Sudan", calling_code: "249", emoji: "🇸🇩" },
  { country: "Suriname", calling_code: "597", emoji: "🇸🇷" },
  { country: "Svalbard and Jan Mayen", calling_code: "47", emoji: "🇸🇯" },
  { country: "Swaziland", calling_code: "268", emoji: "🇸🇿" },
  { country: "Sweden", calling_code: "46", emoji: "🇸🇪" },
  { country: "Switzerland", calling_code: "41", emoji: "🇨🇭" },
  { country: "Syria", calling_code: "963", emoji: "🇸🇾" },
  { country: "Tajikistan", calling_code: "992", emoji: "🇹🇯" },
  { country: "Tanzania", calling_code: "255", emoji: "🇹🇿" },
  { country: "Thailand", calling_code: "66", emoji: "🇹🇭" },
  {
    country: "The Democratic Republic of Congo",
    calling_code: "243",
    emoji: "🇨🇩",
  },
  { country: "Togo", calling_code: "228", emoji: "🇹🇬" },
  { country: "Tokelau", calling_code: "690", emoji: "🇹🇰" },
  { country: "Tonga", calling_code: "676", emoji: "🇹🇴" },
  { country: "Trinidad and Tobago", calling_code: "1868", emoji: "🇹🇹" },
  { country: "Tunisia", calling_code: "216", emoji: "🇹🇳" },
  { country: "Turkey", calling_code: "90", emoji: "🇹🇷" },
  { country: "Turkmenistan", calling_code: "993", emoji: "🇹🇲" },
  { country: "Turks and Caicos Islands", calling_code: "1649", emoji: "🇹🇨" },
  { country: "Tuvalu", calling_code: "688", emoji: "🇹🇻" },
  { country: "Uganda", calling_code: "256", emoji: "🇺🇬" },
  { country: "Ukraine", calling_code: "380", emoji: "🇺🇦" },
  { country: "United Arab Emirates", calling_code: "971", emoji: "🇦🇪" },
  { country: "United Kingdom", calling_code: "44", emoji: "🇬🇧" },
  { country: "United States", calling_code: "1", emoji: "🇺🇸" },
  { country: "Uruguay", calling_code: "598", emoji: "🇺🇾" },
  { country: "Uzbekistan", calling_code: "998", emoji: "🇺🇿" },
  { country: "Vanuatu", calling_code: "678", emoji: "🇻🇺" },
  { country: "Venezuela", calling_code: "58", emoji: "🇻🇪" },
  { country: "Vietnam", calling_code: "84", emoji: "🇻🇳" },
  { country: "Virgin Islands, British", calling_code: "1", emoji: "🇻🇬" },
  { country: "Virgin Islands, U.S.", calling_code: "1", emoji: "🇻🇮" },
  { country: "Wales", calling_code: "44", emoji: "🏴󠁧󠁢󠁷󠁬󠁳󠁿" },
  { country: "Wallis and Futuna", calling_code: "681", emoji: "🇼🇫" },
  { country: "Western Sahara", calling_code: "212", emoji: "🇪🇭" },
  { country: "Yemen", calling_code: "967", emoji: "🇾🇪" },
  { country: "Zambia", calling_code: "260", emoji: "🇿🇲" },
  { country: "Zimbabwe", calling_code: "263", emoji: "🇿🇼" },
];

export const VIDEO_MODULE_TYPES = {
  poll: "polling",
  survey: "surveys",
  quiz: "quiz",
  collaboration: "collaborate"
};
