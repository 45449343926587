import { CREATE_A_COMMENT_SUCCESS } from "../../actions/common/constants";
import {
    LIKE_A_POST_SUCCESS,
    LIKE_A_POST_FAILURE,
    LIKE_A_POST_REQUEST,
} from "../../actions/constants";
import { DELETE_A_COMMENT_SUCCESS } from "../../actions/feed/constants";
import {
    GET_WORKSHOP_DETAILS_FAILURE,
    GET_WORKSHOP_DETAILS_REQUEST,
    GET_WORKSHOP_DETAILS_SUCCESS,
    RESET_WORKSHOP_DETAILS,
} from "../../actions/workshop/constants";

const INITIAL_STATE = {
    fetching: false,
    data: null,
    error: false,
};
export default function view(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_WORKSHOP_DETAILS_REQUEST:
            return {
                ...INITIAL_STATE,
                fetching: true,
            };
        case GET_WORKSHOP_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                hasFetched: true,
                ...action.payload,
            };
        case GET_WORKSHOP_DETAILS_FAILURE:
            return {
                ...state,
                fetching: false,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.payload.error,
                }),
                hasFetched: false,
            };
        case LIKE_A_POST_FAILURE:
        case LIKE_A_POST_SUCCESS:
        case LIKE_A_POST_REQUEST:
            return {
                ...state,
                ...(state.hasFetched && {
                    meta: {
                        ...state.meta,
                        hasLiked:
                            action.type === LIKE_A_POST_REQUEST
                                ? !action.payload.hasLiked
                                : action.payload.hasLiked,
                        likeCount:
                            action.type === LIKE_A_POST_REQUEST
                                ? action.payload.hasLiked
                                    ? state.meta.likeCount === 0
                                        ? 0
                                        : state.meta.likeCount - 1
                                    : state.meta.likeCount + 1
                                : action.type === LIKE_A_POST_FAILURE
                                    ? action.payload.hasLiked
                                        ? state.meta.likeCount + 1
                                        : state.meta.likeCount === 0
                                            ? 0
                                            : state.meta.likeCount - 1
                                    : state.meta.likeCount,
                    },
                }),
            };
        case CREATE_A_COMMENT_SUCCESS: // New comment added
            return {
                ...state,
                ...(state.hasFetched && {
                    meta: action.payload.meta
                        ? {
                            ...state.meta,
                            ...action.payload.meta,
                        }
                        : state.meta,
                }),
            };
        case DELETE_A_COMMENT_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched && {
                    meta: {
                        ...state.meta,
                        commentCount:
                            state.meta.commentCount - 1 === 0
                                ? 0
                                : state.meta.commentCount - 1,
                    },
                }),
            };
        case RESET_WORKSHOP_DETAILS:
            return INITIAL_STATE;
        default:
            return state;
    }
}
