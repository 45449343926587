import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CommonPlayer from "../../../../components/CommonPlayer";
import { availableFeedCardPostTypes } from "../../../../utils/commonVariables";
import {
    GIF_TYPE,
    decodeString,
    removeStripTags,
} from "../../../../utils/helper";
import { workshopLinks } from "../../../../utils/internalLinks";
import MetaBar from "../common/MetaBar";
import PostComments from "../common/PostComments";
import ReadMoreLinkified from "../common/ReadMoreLinkified";
import ReadMoreText from "../common/ReadMoreText";
import ArrowRightIcon from "../../../../assets/images/explore/common/arrow-big-right.svg";


const WorkshopCard = (props) => {
    const {
        cardData,
        isShared,
        showOptionsIcon,
        hideMetaBar,
        isAd,
        commentsData,
        uiType,
    } = props,
        { workshop, meta } = cardData,
        postId = get(workshop, "id"),
        title = decodeString(get(workshop, "title")),
        description = removeStripTags(decodeString(get(workshop, "description")), ["br"]);

    let actionText = "";
    let actionClassName = "";
    const isRegistered = meta && meta.isRegistered;
    const isWorkshopLive = meta && meta.isLive;
    if (isWorkshopLive) {
        actionText = "JOIN WORKSHOP";
        actionClassName = "primary"
    } else if (isRegistered && !isWorkshopLive) {
        actionText = "REGISTERED";
        actionClassName = "light"
    } else if (!isRegistered && !isWorkshopLive) {
        actionText = "REGISTER";
        actionClassName = "dark"
    }
    const linkToGo = workshopLinks.view(postId);
    const hasImageMeta = get(workshop, "image_meta");
    const imagesMeta =
        hasImageMeta && typeof hasImageMeta === "string"
            ? JSON.parse(workshop.image_meta)
            : hasImageMeta;
    const imageToShow =
        imagesMeta && imagesMeta.length && imagesMeta[0].original_photo
            ? imagesMeta[0].original_photo
            : false;
    const gifImage =
        imagesMeta &&
        imagesMeta.length &&
        imagesMeta[0] &&
        imagesMeta[0].meta &&
        imagesMeta[0].meta.mime === GIF_TYPE;
    const isMediaJson =
        workshop.videos_meta &&
            workshop.videos_meta.length &&
            workshop.videos_meta[0] &&
            workshop.videos_meta[0].media_json
            ? workshop.videos_meta[0].media_json
            : false;
    return (
        <div className="feed-card">
            <Link to={linkToGo} className="workshop__card">
                <div className="workshop__card__header--container">
                    {isWorkshopLive ? <span className="workshop__card__status">LIVE</span> : null}
                    <h2 className="workshop__card__header">Workshop</h2>
                </div>
                <ReadMoreLinkified
                    text={title}
                    linksAsDiv={true}
                    className="workshop__card__title"
                    hashtagEventLabel="Workshop"
                />
                <ReadMoreText text={description} className="workshop__card__description" />
                {isMediaJson ? (
                    <div
                        className="workshop__card__img__wrapper workshop__card__img"
                        onClick={(e) => e.preventDefault()}
                    >
                        <CommonPlayer
                            videosMeta={workshop.videos_meta || []} withoverlay={false}
                        />
                    </div>
                ) : (
                    imageToShow && (
                        <div className="workshop__card__img__wrapper">
                            <img
                                src={imageToShow}
                                className={` ${gifImage
                                    ? "workshop__card__img  big_gif_image background_gif"
                                    : "workshop__card__img"
                                    }`}
                                alt={title}
                                style={gifImage ? { border: "1px solid #D1D1D1" } : {}}
                            />
                        </div>
                    )
                )}
                <button className={`workshop__card__action ${actionClassName}`}>
                    {actionText}
                    {isWorkshopLive ?
                        <img src={ArrowRightIcon} className="arrow" alt="join-workshop" />
                        : null}
                </button>
            </Link>
            {!isShared && !hideMetaBar && cardData.meta && (
                <MetaBar
                    postType={availableFeedCardPostTypes.workshop}
                    postId={postId}
                    isAd={isAd}
                    likeActionProcessing={cardData.likeActionProcessing}
                    fetchingComments={commentsData && commentsData.fetching}
                    postToShare={cardData}
                    meta={cardData.meta}
                    uiType={uiType}
                />
            )}
            {commentsData && !hideMetaBar && !isShared && (
                <PostComments
                    showOptionsIcon={showOptionsIcon}
                    commentsData={commentsData}
                    postType={availableFeedCardPostTypes.workshop}
                    isAd={isAd}
                    postId={postId}
                    uiType={uiType}
                />
            )}
        </div>
    );
};

WorkshopCard.propTypes = {
    cardData: PropTypes.object.isRequired,
    isShared: PropTypes.bool,
    showOptionsIcon: PropTypes.bool,
    hideMetaBar: PropTypes.bool,
    isAd: PropTypes.bool,
    showFlagOptions: PropTypes.bool,
    user: PropTypes.object,
    commentsData: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(WorkshopCard);
