import {
    GET_A_POST_COMMENT_REQUEST,
    GET_A_POST_COMMENT_SUCCESS,
    DELETE_A_COMMENT_REQUEST,
    DELETE_A_COMMENT_SUCCESS,
    DELETE_A_COMMENT_FAILURE,
    TOGGLE_POST_COMMENT_CONTAINER,
    RESET_COMMENTS
} from '../../actions/feed/constants';
import {
    CREATE_A_COMMENT_SUCCESS,
    CREATE_A_COMMENT_REQUEST,
    CREATE_A_COMMENT_FAILURE
} from '../../actions/common/constants';

const INITIAL_STATE = {
    byPostId: {}
}

export default function comments(state = INITIAL_STATE, action) {
    const parentPost = action.payload
        ? action.payload.isShared
            ? `${action.payload.postType}-${action.payload.postId}-share-${action.payload.sharedId}`
            : action.payload.isAd
                ? `${action.payload.postType}-${action.payload.postId}-ad`
                : action.payload.uiType
                    ? `${action.payload.postType}-${action.payload.postId}-${action.payload.uiType}`
                    : `${action.payload.postType}-${action.payload.postId}`
        : null;
    switch (action.type) {
        case GET_A_POST_COMMENT_REQUEST:
            return {
                ...state,
                byPostId: {
                    ...state.byPostId,
                    [parentPost]: state.byPostId[parentPost]
                        ? {
                            ...state.byPostId[parentPost],
                            fetching: !action.payload.fetchingPrev && !action.payload.fetchingNext,
                            fetchingPrev: action.payload.fetchingPrev,
                            fetchingNext: action.payload.fetchingNext,
                            comments: action.payload.page === 1
                                ? []
                                : state.byPostId[parentPost].comments
                        }
                        : {
                            fetching: true,
                            comments: []
                        }
                }
            };
        case GET_A_POST_COMMENT_SUCCESS:
            return {
                ...state,
                byPostId: {
                    ...state.byPostId,
                    [parentPost]: state.byPostId[parentPost]
                        ? {
                            ...state.byPostId[parentPost],
                            comments: action.payload.fetchingPrev
                                ? [...action.payload.comments.reverse(), ...state.byPostId[parentPost].comments]
                                : [...state.byPostId[parentPost].comments, ...action.payload.comments.reverse()],
                            fetching: false,
                            previousPage: action.payload.previousPage,
                            fetchingPrev: false,
                            fetchingNext: false,
                            showComments: true
                        }
                        : {
                            comments: action.payload.comments.reverse(), // Doing it to render recent first
                            nextPage: action.payload.nextPage,
                            fetching: false,
                            fetchingPrev: false,
                            fetchingNext: false,
                            showComments: true
                        }
                }
            }
        case CREATE_A_COMMENT_REQUEST:
            return {
                ...state,
                byPostId: {
                    ...state.byPostId,
                    [parentPost]: state.byPostId[parentPost] // Parent Id exists
                        ? {
                            ...state.byPostId[parentPost],
                            updating: true,
                            errorUpdating: false
                        }
                        : {
                            updating: true,
                            comments: [],
                            errorUpdating: false
                        }
                }
            }
        case CREATE_A_COMMENT_SUCCESS:
            return {
                ...state,
                byPostId: {
                    ...state.byPostId,
                    [parentPost]: {
                        ...state.byPostId[parentPost],
                        comments: [...state.byPostId[parentPost].comments, action.payload.comment],
                        updating: false,
                        errorUpdating: false
                    }
                }
            }
        case CREATE_A_COMMENT_FAILURE:
            return {
                ...state,
                byPostId: {
                    ...state.byPostId,
                    [parentPost]: {
                        ...state.byPostId[parentPost],
                        updating: false,
                        errorUpdating: true
                    }
                }
            }
        case DELETE_A_COMMENT_REQUEST:
            return {
                ...state,
                byPostId: {
                    ...state.byPostId,
                    [parentPost]: {
                        ...state.byPostId[parentPost],
                        deletingComment: true,
                    }
                }
            }
        case DELETE_A_COMMENT_SUCCESS:
            return {
                ...state,
                byPostId: {
                    ...state.byPostId,
                    [parentPost]: {
                        ...state.byPostId[parentPost],
                        deletingComment: false,
                        comments: state.byPostId[parentPost].comments.filter((comment) => comment.id !== action.payload.commentId)
                    }
                }
            }
        case DELETE_A_COMMENT_FAILURE:
            return {
                ...state,
                byPostId: {
                    ...state.byPostId,
                    [parentPost]: {
                        ...state.byPostId[parentPost],
                        deletingComment: false,
                    }
                }
            }
        case TOGGLE_POST_COMMENT_CONTAINER:
            return {
                ...state,
                byPostId: {
                    ...state.byPostId,
                    [parentPost]: {
                        ...state.byPostId[parentPost],
                        showComments: !state.byPostId[parentPost].showComments
                    }
                }
            }
        case RESET_COMMENTS:
            return INITIAL_STATE
        default:
            return state;
    }
}